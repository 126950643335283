import { useStaticQuery, graphql } from "gatsby";
import React from "react";

const JmpPage = () => {
    const data = useStaticQuery(graphql`
        query MyQuery {
            file(relativePath: {eq: "NYC_Parks_Diversity.pdf"}) {
                publicURL
                name
            }
        }
    `);
    return <head>
        <meta http-equiv="refresh" content={"0; URL=" + data.file.publicURL} />
        <title>Job Market Paper</title>
    </head>
  };
  
  export default JmpPage;